import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/seo'
import TermsOfService from '../components/Terms/tos'

const Terms = () => (
    <Layout>
      <SEO title="NagNag"></SEO>
      <TermsOfService />
    </Layout>
  )
  
  export default Terms;
